import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { ProductActions } from ".";
import {
  CLEAR_PRODUCT,
  CLEAR_PRODUCTS_STATE,
  FETCH_PRODUCTS_LIST_FAILED,
  FETCH_PRODUCTS_LIST_PROGRESS,
  FETCH_PRODUCTS_LIST_SUCCESS,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_PROGRESS,
  FETCH_PRODUCT_SUCCESS,
} from "./productActions";
import { defaultProductState } from "./defaultState";

export const productReducer = (
  state: IStoreState["product"] = defaultProductState,
  action: ProductActions,
) => {
  switch (action.type) {
    case FETCH_PRODUCTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.products.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCTS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.products.loading = LoadState.Loaded;
        draftState.products.data = data;
        draftState.products.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.products.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_PRODUCT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.product.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.product.loading = LoadState.Loaded;
        draftState.product.data = data;
      });
      return newState;
    }
    case FETCH_PRODUCT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.product.loading = LoadState.Failed;
        draftState.product.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_PRODUCT: {
      const newState = produce(state, (draftState) => {
        draftState.product.loading = LoadState.NotLoaded;
        draftState.product.data = defaultProductState["product"].data;
      });
      return newState;
    }
    case CLEAR_PRODUCTS_STATE: {
      return defaultProductState;
    }
    default: {
      return state;
    }
  }
};
