import React from "react";
import { Navigate } from "react-router-dom";
import { useRoles } from "../security/RolesProvider/RolesProvider";
import {
  getAccessibleModulePermissions,
  isAccessibleModule,
} from "../security/isModuleAccess";
import { PermissionsProvider } from "../security/PermissionsProvider/PermissionsProvider";

interface IAuthorizedRouteProps {
  moduleIds: string[];
  allowFullAccess?: boolean;
  children: React.ReactNode;
}

export const AuthorizedRoute: React.FC<IAuthorizedRouteProps> = (props) => {
  const { moduleIds, allowFullAccess } = props;
  const { roles } = useRoles();

  if (isAccessibleModule(moduleIds, roles, allowFullAccess)) {
    const permissions = getAccessibleModulePermissions(
      moduleIds,
      roles,
      allowFullAccess,
    );

    return (
      <PermissionsProvider permissions={permissions}>
        {props.children}
      </PermissionsProvider>
    );
  }

  return <Navigate to="/access" />;
};
