import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { PURCHASE_ORDER_ROUTE } from "./PurchaseOrder.constants";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ALLOWED_MODULES } from "../../constants/enums";

//************************ Purchase Order Imports ***************************/
const PurchaseOrderTable = Loadable(
  lazy(() =>
    import("./PurchaseOrderTable").then(({ PurchaseOrderTable }) => ({
      default: PurchaseOrderTable,
    })),
  ),
);

const ManagePurchaseOrder = Loadable(
  lazy(() =>
    import("./ManagePurchaseOrder").then(({ ManagePurchaseOrder }) => ({
      default: ManagePurchaseOrder,
    })),
  ),
);

//************************ Purchase Order Routes ***************************/
export const purchaseOrderRoutes = [
  {
    path: PURCHASE_ORDER_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PURCHASE_ORDER]}>
        <PurchaseOrderTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PURCHASE_ORDER_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PURCHASE_ORDER]}>
        <ManagePurchaseOrder />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PURCHASE_ORDER_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PURCHASE_ORDER]}>
        <ManagePurchaseOrder />
      </AuthorizedRoute>
    ),
  },
];
