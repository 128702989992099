import { LoadState } from "../../constants/enums";
import { IInvoiceExport, IInvoiceExportState } from "./invoiceExport.types";

export const defaultInvoiceExport: IInvoiceExport = {
  invoice_export_data_uuid: null,
  invoice_uuid: "",
  invoice_no: "",
  ship_bill_no: null,
  ship_bill_date: null,
  ship_port_code: null,
  precarriage_by: null,
  place_of_precarriage: null,
  vessel_no: null,
  port_of_loading: null,
  port_of_discharge: null,
  final_destination: null,
  country_of_origin: null,
  country_of_final: null,
  weight: null,
  packages: null,
  status: "ACTIVE",
};
export const defaultInvoiceExportState: IInvoiceExportState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  invoiceExport: {
    data: defaultInvoiceExport,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
