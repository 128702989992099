import { LoadState } from "../../constants/enums";
import { IProduct, IProductState } from "./products.types";

export const defaultProduct: IProduct = {
  product_uuid: null,
  product_name: null,
  item_type: "PRODUCT",
  hsn_code: null,
  unit: null,
  product_type: null,
  gst: null,
  cess: null,
  cess_amount: null,
  no_itc: "YES",
  category_uuid: null,
  category_name: "",
  sell_price: "",
  sell_price_tax: "",
  purchase_price: "",
  purchase_price_tax: "",
  image: "",
  colour: "",
  description: "",
  status: "ACTIVE",
};

export const defaultProductState: IProductState = {
  products: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  product: {
    data: defaultProduct,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
