import { LoadState } from "../../constants/enums";
// initial values
export const initialTentativeDocument: ITentativeDocumentNo = {
  billing_company_branch_uuid: "",
  table_name: "latest_invoices",
};

export interface ICommonState {
  policies: {
    list: IPolicies[];
    loadState: LoadState;
  };
  generalHistory: {
    list: IGeneralHistory[];
    loading: LoadState;
  };
  recordCounts: {
    list: IRecordCount[];
    loading: LoadState;
  };
  tentativeDocuments: {
    data: string;
    loading: LoadState;
  };
}

export interface IRecordCount {
  name: string;
  cnt: number;
}

export interface IPolicies {
  type: string;
  subTypes: {
    policy_id: number;
    name: string;
  }[];
}

export interface IGeneralHistory {
  [key: string]: any;
}

export const defaultCommonState: ICommonState = {
  policies: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  generalHistory: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  recordCounts: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  tentativeDocuments: {
    data: "",
    loading: LoadState.NotLoaded,
  },
};

export interface IMail {
  emails: string[];
  subject: string;
  body: string;
  templateName: string | null;
  objectVariables: any;
  cc: string[];
  bcc: string[];
  reply_to?: {
    email: string;
    name: string;
  };
  attachments?: {
    content: string;
    filename: string;
  }[];
}

export interface IQueryParams {
  status: string;
  page: number;
  rowsPerPage: number;
  columns: string[];
  value: string;
  fromDate?: string;
  toDate?: string;
}

export interface ITentativeDocumentNo {
  billing_company_branch_uuid: string;
  table_name:
    | "latest_invoices"
    | "latest_proforma_invoices"
    | "latest_credit_debit_note"
    | "latest_delivery_challan";
  credit_debit_note_type?: "CREDIT" | "DEBIT";
}
export type ACTIVE_STATUS = "ACTIVE" | "INACTIVE";
