import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { getSearchQuery } from "../common/helpers";
import { IQueryParams } from "../common/common.types";
import { uploadFile } from "../../helpers/uploadFile";
import { IQuotation } from "./quotation.types";

//=============================== Goods Receiving Note List ===============================
export const FETCH_QUOTATION_LIST_PROGRESS = "FETCH_QUOTATION_LIST_PROGRESS";
export const FETCH_QUOTATION_LIST_SUCCESS = "FETCH_QUOTATION_LIST_SUCCESS";
export const FETCH_QUOTATION_LIST_FAILED = "FETCH_QUOTATION_LIST_FAILED";

export const fetchQuotationListProgress = () =>
  action(FETCH_QUOTATION_LIST_PROGRESS);
export const fetchQuotationListSuccess = (
  list: IQuotation[],
  totalRecords: number,
) => action(FETCH_QUOTATION_LIST_SUCCESS, { list, totalRecords });
export const fetchQuotationListFailed = () =>
  action(FETCH_QUOTATION_LIST_FAILED);

export const fetchQuotationListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchQuotationListProgress());

      const res = await api.get(
        `/quotation/get-quotation${searchQuery}&status=ACTIVE`,
      );
      const data: IQuotation[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchQuotationListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchQuotationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Goods Receiving Note ===============================
export const FETCH_SINGLE_QUOTATION_PROGRESS =
  "FETCH_SINGLE_QUOTATION_PROGRESS";
export const FETCH_SINGLE_QUOTATION_SUCCESS = "FETCH_SINGLE_QUOTATION_SUCCESS";
export const FETCH_SINGLE_QUOTATION_FAILED = "FETCH_SINGLE_QUOTATION_FAILED";

export const fetchSingleQuotationProgress = () =>
  action(FETCH_SINGLE_QUOTATION_PROGRESS);
export const fetchSingleQuotationSuccess = (data: IQuotation) =>
  action(FETCH_SINGLE_QUOTATION_SUCCESS, { data });
export const fetchSingleQuotationFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_QUOTATION_FAILED, { errorMessage });

export const fetchSingleQuotationAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleQuotationProgress());
      const res = await api.get(
        `/quotation/get-quotation?quotation_uuid=${uuid}`,
      );
      const data: IQuotation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleQuotationSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleQuotationFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleQuotationFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Single Goods Receiving Note ===============================
export const upsertSingleQuotationAsync =
  (
    payload: IQuotation,
    onCallback: (isSuccess: boolean, grnUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const { create_ts, insert_ts, combined_quotation_no, ...restPayload } =
        payload;
      const res = await api.post("/quotation/upsert-quotation", restPayload);

      const responseData: IQuotation = res.data.data;

      onCallback(true, responseData.quotation_uuid as string);
      dispatch(
        showMessage({
          type: "success",
          message: "Quotation saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Delete Single Quotation ===============================
export const deleteSingleQuotationAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.delete("/quotation/delete-quotation", {
        data: { quotation_uuid: uuid },
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Quotation deleted successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Clear State ===============================
export const CLEAR_SINGLE_QUOTATION = "CLEAR_SINGLE_QUOTATION";
export const CLEAR_QUOTATION_STATE = "CLEAR_QUOTATION_STATE";
export const clearSingleQuotationSync = () => action(CLEAR_SINGLE_QUOTATION);
export const clearQuotationStateSync = () => action(CLEAR_QUOTATION_STATE);
