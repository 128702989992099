import moment from "moment";
import {
  STANDARD_APP_DATE_FORMAT,
  STANDARD_APP_DATE_TIME_FORMAT,
} from "../constants/constants";
import dayjs from "dayjs";

export const convertDate = (targetDate: string) => {
  let date = new Date(targetDate);

  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  console.log(`${date.getFullYear()}-0${date.getMonth()}-${date.getDate()}`);
  console.log(date);

  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};

export const formatDate = (date: string) => {
  return moment(date).format(STANDARD_APP_DATE_FORMAT);
};

export const formatDateWithTime = (date: string) => {
  return moment(date).format(STANDARD_APP_DATE_TIME_FORMAT);
};

export const showFormatDate = (date: string | null) => {
  if (!date) return "--";
  return dayjs(date).format("YYYY-MM-DD");
};
