import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ICustomerBranch } from "./customBranch.types";

export const FETCH_CUSTOMER_BRANCH_LIST_PROGRESS =
  "FETCH_CUSTOMER_BRANCH_LIST_PROGRESS";
export const FETCH_CUSTOMER_BRANCH_LIST_SUCCESS =
  "FETCH_CUSTOMER_BRANCH_LIST_SUCCESS";
export const FETCH_CUSTOMER_BRANCH_LIST_FAILED =
  "FETCH_CUSTOMER_BRANCH_LIST_FAILED";

export const fetchCustomerBranchListProgress = () =>
  action(FETCH_CUSTOMER_BRANCH_LIST_PROGRESS);
export const fetchCustomerBranchListSuccess = (
  data: ICustomerBranch[],
  totalRecords: number,
) => action(FETCH_CUSTOMER_BRANCH_LIST_SUCCESS, { data, totalRecords });
export const fetchCustomerBranchListFailed = () =>
  action(FETCH_CUSTOMER_BRANCH_LIST_FAILED);

export const fetchCustomerBranchListAsync =
  (queryParams: IQueryParams, customerUUID?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchCustomerBranchListProgress());
        let finalUrl = `/customer/get-customer-branch${searchQuery}&customer_uuid=${customerUUID}`;
        const res = await api.get(finalUrl);
        const data: ICustomerBranch[] = res.data.data;
        const totalRecords = res.data.totalRecords;
        dispatch(fetchCustomerBranchListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchCustomerBranchListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertCustomerBranchAsync =
  (
    customerBranch: ICustomerBranch,
    onCallback: (isSuccess: boolean, customerBranch?: ICustomerBranch) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        const { create_ts, insert_ts, rowId, ...rest } = customerBranch;
        const res = await api.post(
          "/customer/upsert-customer-branch",
          rest,
        );
        const data = res.data.data;
        onCallback(true, data);
        dispatch(
          showMessage({
            type: "success",
            message: "Customer Branch saved successfully!",
            displayAs: "snackbar",
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const FETCH_CUSTOMER_BRANCH_PROGRESS = "FETCH_CUSTOMER_BRANCH_PROGRESS";
export const FETCH_CUSTOMER_BRANCH_SUCCESS = "FETCH_CUSTOMER_BRANCH_SUCCESS";
export const FETCH_CUSTOMER_BRANCH_FAILED = "FETCH_CUSTOMER_BRANCH_FAILED";

export const fetchCustomerBranchProgress = () =>
  action(FETCH_CUSTOMER_BRANCH_PROGRESS);
export const fetchCustomerBranchSuccess = (data: ICustomerBranch) =>
  action(FETCH_CUSTOMER_BRANCH_SUCCESS, { data });
export const fetchCustomerBranchFailed = (errorMessage: string) =>
  action(FETCH_CUSTOMER_BRANCH_FAILED, { errorMessage });

export const fetchCustomerBranchAsync =
  (customerBranchUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchCustomerBranchProgress());
        const res = await api.get(
          `/customer/get-customer-branch?customer_branch_uuid=${customerBranchUUID}`,
        );
        const data: ICustomerBranch[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchCustomerBranchSuccess(data[0]));
        } else {
          dispatch(
            fetchCustomerBranchFailed("Oops! We couldn't find any records."),
          );
        }
      } catch (err: any) {
        dispatch(fetchCustomerBranchFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_CUSTOMER_BRANCH = "CLEAR_CUSTOMER_BRANCH";
export const CLEAR_CUSTOMER_BRANCH_STATE = "CLEAR_CUSTOMER_BRANCH_STATE";

export const clearCustomerBranch = () => action(CLEAR_CUSTOMER_BRANCH);
export const clearCustomerBranchState = () =>
  action(CLEAR_CUSTOMER_BRANCH_STATE);
