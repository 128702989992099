import produce from "immer";
import { ApprovalsActions, defaultApprovalState } from ".";
import { IStoreState } from "../../initialStoreState";
import {
  CLEAR_APPROVALS_STATE,
  FETCH_APPROVAL_LIST_FAILED,
  FETCH_APPROVAL_LIST_PROGRESS,
  FETCH_APPROVAL_LIST_SUCCESS,
} from "./approvals.actions";
import { LoadState } from "../../../constants/enums";

export const approvalsReducer = (
  state: IStoreState["approvals"] = defaultApprovalState,
  action: ApprovalsActions,
) => {
  switch (action.type) {
    case FETCH_APPROVAL_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.approvals_list.loading = LoadState.InProgress;
        draftState.approvals_list.data = [];
        draftState.approvals_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_APPROVAL_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.approvals_list.loading = LoadState.Loaded;
        draftState.approvals_list.data = list;
        draftState.approvals_list.totalRecords = totalRecords;
      });
      return newState;
    }

    case FETCH_APPROVAL_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.approvals_list.loading = LoadState.Failed;
        draftState.approvals_list.data = [];
        draftState.approvals_list.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_APPROVALS_STATE: {
      return defaultApprovalState;
    }
    default: {
      return state;
    }
  }
};
