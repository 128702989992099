import React from "react";
import { IPermissions } from "../RolesProvider/RolesProvider";

interface IPermissionsContext {
  permissions: IPermissions[];
}

const PermissionsContext = React.createContext<IPermissionsContext>({
  permissions: [],
});

export const usePermissions = () => {
  const { permissions } = React.useContext(PermissionsContext);

  const getPermissionsByModuleId = (moduleId: string) => {
    const permission = permissions.find((x) => x.table_name === moduleId);
    if (permission) {
      return permission;
    } else {
      return {
        column_relation_options: [],
        filter_values: {},
        module_id: -1,
        module_name: "",
        role_id: -1,
        role_name: "",
        submodule_name: "",
        table_name: "",
        role_module_code: "",
        view_access: -1,
        edit_access: -1,
        bulk_export: -1,
        bulk_import: -1,
        send_mail: -1,
        send_sms: -1,
        send_whatsapp: -1,
        send_call: -1,
      };
    }
  };

  return {
    permissionsList: permissions,
    getPermissionsByModuleId,
  };
};

export const PermissionsProvider: React.FC<{
  children: React.ReactNode;
  permissions: IPermissions[];
}> = (props) => {
  return (
    <PermissionsContext.Provider
      value={{
        permissions: props.permissions,
      }}
    >
      {props.children}
    </PermissionsContext.Provider>
  );
};
