import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ICategory } from "./category.types";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

//=============================== Category List ===============================

export const FETCH_CATEGORY_LIST_PROGRESS = "FETCH_CATEGORY_LIST_PROGRESS";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS";
export const FETCH_CATEGORY_LIST_FAILED = "FETCH_CATEGORY_LIST_FAILED";

export const fetchCategoryListProgress = () =>
  action(FETCH_CATEGORY_LIST_PROGRESS);
export const fetchCategoryListSuccess = (
  data: ICategory[],
  totalRecords: number,
) => action(FETCH_CATEGORY_LIST_SUCCESS, { data, totalRecords });
export const fetchCategoryListFailed = () => action(FETCH_CATEGORY_LIST_FAILED);

export const fetchCategoryListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCategoryListProgress());
      const res = await api.get(`category/get-category${searchQuery}`);
      const data: ICategory[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchCategoryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCategoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Category ===============================

export const upsertCategoryAsync =
  (
    category: ICategory,
    onCallback: (isSuccess: boolean, product?: ICategory) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const {create_ts, insert_ts, rowId,  ...rest}= category; 
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/category/upsert_category", rest);
      dispatch(
        showMessage({
          type: "success",
          message: "Category saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const CLEAR_CATEGORY_STATE = "CLEAR_CATEGORY_STATE";
export const clearCategory = () => action(CLEAR_CATEGORY);
export const clearCategoryState = () => action(CLEAR_CATEGORY_STATE);
