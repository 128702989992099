import { LoadState } from "../../constants/enums";
import {
  IInventory,
  IInventoryProduct,
  IInventoryState,
} from "./inventory.types";

export const defaultInventoryProduct: IInventoryProduct = {
  product_uuid: null,
  product_name: null,
  description: null,
  item_type: null,
  HSN: null,
  quantity: 0,
  rate: 0,
  unit: null,
  other_unit: "",
  discount: 0,
  discount_amount: 0,
  cess_rate: 0,
  cess_amount: 0,
  taxable_amount: 0,
  tax_rate: 0,
  tax_amount: 0,
  tax_type: "IGST",
  amount: 0,
};

export const defaultInventory: IInventory = {
  inventory_uuid: null,
  inv_invoice_no: "",
  purchase_invoice_type: "",
  dated: null,
  challan_no: null,
  challan_date: null,
  po_number: null,
  eway_bill_no: null,
  lr_no: null,
  delivery_note: null,
  mode_of_payment: null,
  reference_no: null,
  other_references: null,
  buyers_order_no: null,
  dispatch_doc_no: null,
  delivery_note_date: null,
  dispatched_through: "Hand Delivery",
  destination: null,
  bill_of_lading: null,
  motor_vehicle_no: null,
  products: [defaultInventoryProduct],
  total_amount_after_tax: 0,
  consignee_name: "",
  consignee_uuid: "",
  consignee_address_line1: null,
  consignee_address_line2: null,
  consignee_address_city: null,
  consignee_address_state: null,
  consignee_address_country: null,
  consignee_GSTIN: null,
  consignee_code: null,
  consignee_address_pincode: null,
  buyer_name: "",
  buyer_uuid: "",
  buyer_branch_name: "",
  buyer_branch_uuid: "",
  buyer_address_line1: null,
  buyer_address_line2: null,
  buyer_address_city: null,
  buyer_address_state: null,
  buyer_address_country: null,
  buyer_GSTIN: null,
  buyer_code: null,
  buyer_address_pincode: null,
  seller_name: "",
  seller_uuid: "",
  vendor_registration_type: "",
  seller_address_line1: null,
  seller_address_line2: null,
  seller_address_city: null,
  seller_address_state: "",
  seller_address_country: null,
  seller_GSTIN: null,
  seller_code: null,
  seller_address_pincode: null,
  dispatch_through: null,
  transport_id: null,
  transport_doc_no: null,
  transport_doc_date: null,
  bank_account_uuid: "",
  bank_account_name: "",
  document: null,
  status: "ACTIVE",
};

export const defaultInventoryState: IInventoryState = {
  inventoryList: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  inventory: {
    data: defaultInventory,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
