import { combineReducers } from "redux";

import { IStoreState } from "./initialStoreState";
import { messageReducer } from "./messages/messagesReducer";
import { customerReducer } from "./customer/customerReducer";

import { commonReducer } from "./common/commonReducer";

import { UserProfileReducer } from "./UserProfileList/userProfileReducer";

import { opportunitesReducer } from "./opportunites/opportunitesReducer";
import { customizerReducer } from "./customizer/customizerReducer";
import { contactsReducer } from "./contacts/contactsReducer";
import { productReducer } from "./products/productReducer";
import { invoiceReducer } from "./invoice/invoiceReducer";
import { tasksBoardReducer } from "./taskBoard/taskBoardReducer";
import { renderingReducer } from "./rendering/renderingReducer";
import { categoryReducer } from "./category/categoryReducer";
import { ledgerReducer } from "./ledger/ledgerReducer";
import { inventoryReducer } from "./inventory/inventoryReducer";
import { billingCompanyReducer } from "./billingCompany";
import { bankDetailsReducer } from "./bankDetails/bankDetailsReducer";
import { vendorReducer } from "./vendor";
import { customerBranchReducer } from "./customerBranch";
import { creditNoteReducer } from "./creditNote";
import { proformaInvoiceReducer } from "./proformaInvoice";
import { customerDeliveryAddressReducer } from "./customerDeliveryAddress";
import { deliveryChallanReducer } from "./deliveryChallan";
import { customerDispatchAddressReducer } from "./customerDispatchAddress";
import { invoiceExportReducer } from "./invoiceExport";
import { customerVendorAttachmentReducer } from "./customerVendorAttachment";
import { analyticsReducer } from "./analytics";
import { goodsReceivingNoteReducer } from "./goods-receiving-note";
import { saleOrderReducer } from "./sale-order";
import { purchaseOrderReducer } from "./purchase-order";
import { quotationReducer } from "./quotation";
import { securityReducer } from "./security/securityReducer";
import { approvalsReducer } from "./approvals/approvals";
import { projectsReducer } from "./projects";

const appReducer = combineReducers({
  customer: customerReducer,
  userprofile: UserProfileReducer,
  opportunites: opportunitesReducer,
  customizerReducer: customizerReducer,
  category: categoryReducer,
  contacts: contactsReducer,
  product: productReducer,
  message: messageReducer,
  common: commonReducer,
  invoices: invoiceReducer,
  deliveryChallan: deliveryChallanReducer,
  taskBoard: tasksBoardReducer,
  rendering: renderingReducer,
  ledger: ledgerReducer,
  inventory: inventoryReducer,
  billingCompany: billingCompanyReducer,
  analytics: analyticsReducer,
  invoiceExport: invoiceExportReducer,
  bankDetails: bankDetailsReducer,
  vendor: vendorReducer,
  customerBranch: customerBranchReducer,
  customerDeliveryAddress: customerDeliveryAddressReducer,
  customerDispatchAddress: customerDispatchAddressReducer,
  customerVendorAttachment: customerVendorAttachmentReducer,
  creditNote: creditNoteReducer,
  proformaInvoice: proformaInvoiceReducer,
  grn: goodsReceivingNoteReducer,
  purchase_order: purchaseOrderReducer,
  sale_order: saleOrderReducer,
  quotation: quotationReducer,
  security: securityReducer,
  approvals: approvalsReducer,
  project: projectsReducer,
});

export const rootReducer = (state: IStoreState | undefined, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
