import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IRendering, IRenderingPartner } from "./rendering.types";
import { IFileUpload } from "../../components/FileUpload/FileUpload.type";
import { uploadMultipleFiles } from "../../helpers/uploadFile";

//=============================== Rendering List ===============================

export const FETCH_RENDERING_LIST_PROGRESS = "FETCH_RENDERING_LIST_PROGRESS";
export const FETCH_RENDERING_LIST_SUCCESS = "FETCH_RENDERING_LIST_SUCCESS";
export const FETCH_RENDERING_LIST_FAILED = "FETCH_RENDERING_LIST_FAILED";

export const fetchRenderingListProgress = () =>
  action(FETCH_RENDERING_LIST_PROGRESS);
export const fetchRenderingListSuccess = (
  list: IRendering[],
  totalRecords: number,
) => action(FETCH_RENDERING_LIST_SUCCESS, { list, totalRecords });
export const fetchRenderingListFailed = (errorMessage: string) =>
  action(FETCH_RENDERING_LIST_FAILED, { errorMessage });

export const fetchRenderingListAsync =
  (
    renderingPartnerId: string,
    pageNumber: number,
    rowsInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRenderingListProgress());
      const res = await api.get(
        `/history/get-history?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`,
      ); //---> need to replace
      const data: IRendering[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchRenderingListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchRenderingListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Rendering Single Record ===============================

export const FETCH_RENDERING_PROGRESS = "FETCH_RENDERING_PROGRESS";
export const FETCH_RENDERING_SUCCESS = "FETCH_RENDERING_SUCCESS";
export const FETCH_RENDERING_FAILED = "FETCH_RENDERING_FAILED";

export const fetchRenderingProgress = () => action(FETCH_RENDERING_PROGRESS);
export const fetchRenderingSuccess = (
  list: IRenderingPartner[],
  totalRecords: number,
) => action(FETCH_RENDERING_SUCCESS, { list, totalRecords });
export const fetchRenderingFailed = (errorMessage: string) =>
  action(FETCH_RENDERING_FAILED, { errorMessage });

export const fetchRenderingAsync =
  (
    renderingPartnerId: string,
    pageNumber: number,
    rowsInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRenderingProgress());
      const res = await api.get(
        `/history/get-history?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`,
      ); //---> need to replace
      const data: IRenderingPartner[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchRenderingSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchRenderingFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Insert/Update Rendering ===============================
export const upsertRenderingAysnc =
  (
    rendering: IRendering,
    files: IFileUpload[],
    onCallback: (isSuccess: boolean, renderingUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let { rendering_id, ...payload } = rendering;

      if (files.length > 0) {
        const asPayload = {
          project_name: rendering.project_name,
        };
        const result = await uploadMultipleFiles(files, "PROJECT", asPayload);
        payload.attachments = Object.assign({}, result);
      }

      const result = await api.post("/rendering/upsert-rendering", payload); //---> need to replace
      let message = "Rendering is saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true, result.data?.data?.rendering_uuid);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//===============================  Clear Rendering  ===============================
export const CLEAR_RENDERING_STATE = "CLEAR_RENDERING_STATE";
export const CLEAR_RENDERING = "CLEAR_RENDERING";

export const clearRenderingState = () => action(CLEAR_RENDERING_STATE);
export const clearRendering = () => action(CLEAR_RENDERING);
