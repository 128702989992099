import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { defaultRenderingState } from "./defaultState";
import { RenderingActions } from ".";
import {
  FETCH_RENDERING_LIST_FAILED,
  FETCH_RENDERING_LIST_PROGRESS,
  FETCH_RENDERING_LIST_SUCCESS,
} from "./renderingActions";

export const renderingReducer = (
  state: IStoreState["rendering"] = defaultRenderingState,
  action: RenderingActions,
) => {
  switch (action.type) {
    //=============================== Rendering Partner List ===============================
    case FETCH_RENDERING_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rendering_list.loading = LoadState.InProgress;
        draftState.rendering_list.data = [];
        draftState.rendering_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_RENDERING_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering_list.loading = LoadState.Loaded;
        draftState.rendering_list.data = list;
        draftState.rendering_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_RENDERING_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering_list.loading = LoadState.Failed;
        draftState.rendering_list.data = [];
      });
      return newState;
    }

    // ---------------------------------- Lead List ----------------------------------
    // case FETCH_LEADS_LIST_PROGRESS: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.leadList.loading = LoadState.InProgress;
    //     draftState.leadList.data = [];
    //     draftState.leadList.totalRecords = 0;
    //   });
    //   return newState;
    // }
    // case FETCH_LEADS_LIST_SUCCESS: {
    //   const { list, totalRecords } = action.payload;
    //   const newState = produce(state, (draftState) => {
    //     draftState.leadList.loading = LoadState.Loaded;
    //     draftState.leadList.data = list;
    //     draftState.leadList.totalRecords = totalRecords;
    //   });
    //   return newState;
    // }
    // case FETCH_LEADS_LIST_FAILED: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.leadList.loading = LoadState.InProgress;
    //     draftState.leadList.data = [];
    //     draftState.leadList.totalRecords = 0;
    //   });
    //   return newState;
    // }

    // case FETCH_LEAD_PROGRESS: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.lead.loading = LoadState.InProgress;
    //     draftState.lead.data = initialLead;
    //   });
    //   return newState;
    // }
    // case FETCH_LEAD_SUCCESS: {
    //   const { lead } = action.payload;
    //   const newState = produce(state, (draftState) => {
    //     draftState.lead.loading = LoadState.Loaded;
    //     draftState.lead.data = lead;
    //   });
    //   return newState;
    // }
    // case FETCH_LEAD_FAILED: {
    //   const { errorMessage } = action.payload;
    //   const newState = produce(state, (draftState) => {
    //     draftState.lead.loading = LoadState.Failed;
    //     draftState.lead.error = errorMessage;
    //   });
    //   return newState;
    // }
    // case CLEAR_LEAD: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.lead.loading = LoadState.NotLoaded;
    //     draftState.lead.data = initialLead;
    //     draftState.lead.error = null;
    //   });
    //   return newState;
    // }
    // case CLEAR_PROJECT: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.project.loading = LoadState.NotLoaded;
    //     draftState.project.data = initialProject;
    //     draftState.project.error = null;
    //   });
    //   return newState;
    // }
    // case CLEAR_LEAD_STATE: {
    //   return initialLeadState;
    // }

    // ---------------------------------- Default ----------------------------------
    default: {
      return state;
    }
  }
};
