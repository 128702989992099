import { Box, Chip, Typography } from "@mui/material";

export const CustomStatus: React.FC<{
    title?: string;
    value: string;
    variant?: "h5";
    size?: "small" | "medium";
  }> = (props) => {
    const { title, value, variant, size } = props;
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography variant={variant || "h5"} fontWeight={600}>
          {title}
        </Typography>
        <Chip
          sx={{
            backgroundColor: "primary.main",
            color: "#fff",
            borderRadius: 2,
            fontWeight: 600,
          }}
          label={value}
          content={value}
          size={size}
        />
      </Box>
    );
  };