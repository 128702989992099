import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import "./App.css";
import { RTL } from "./layout/fullLayout/customizer/RTL";
import { ThemeSettings } from "./layout/fullLayout/customizer/ThemeSettings";
import { IStoreState } from "./redux/initialStoreState";
import { Router } from "./router/router";
import { useDispatchWrapper } from "./hooks";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FontsProvider } from "./contexts/FontsProvider/FontsProvider";

function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const dispatch = useDispatchWrapper();
  const customizer = useSelector(
    (state: IStoreState) => state.customizerReducer,
  );

  return (
    <ThemeProvider theme={theme}>
      <FontsProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <RTL direction={customizer.activeDir as "ltr"}>
            <CssBaseline />
            {routing}
          </RTL>
        </LocalizationProvider>
      </FontsProvider>
    </ThemeProvider>
  );
}

export default App;
