import { LoadState } from "../../constants/enums";
import { defaultProjectItem, IProject, IProjectState } from "./projects.types";

export const defaultProject: IProject = {
  project_uuid: null,
  project_name: "",
  project_code: null,
  project_location: null,
  nit_no: null,
  bid_no: null,
  bid_date: "", // YYYY-MM-DD
  contract_no: null,
  agency_name: null,
  start_date: "", // YYYY-MM-DD
  completion_date: "", // YYYY-MM-DD
  estimated_cost: 0,
  tender_amount: 0,
  project_managers: [],
  remark: null,
  items: [defaultProjectItem],
  status: "ACTIVE",
};

export const defaultProjectState: IProjectState = {
  project_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  single_project: {
    data: defaultProject,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
