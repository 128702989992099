import { LoadState } from "../../constants/enums";
import { IAnalyticsState } from "./analytics.types";

// export const initialInvoiceAnalyticsState: IInvoiceAnalytics = {
//   analytics_invoice_id: 0,
//   calendar_id: 0,
//   analytics_invoice_uuid: "",
//   date_range: "",
//   total_invoice_amount: 0,
//   total_tax_amount: 0,
//   total_discount_amount: 0,
//   total_taxable_amount: 0,
//   total_sum_tax_amount: 0,
//   total_sum_discount_amount: 0,
//   total_sum_taxable_amount: 0,
//   sum_tax_amount: 0,
//   sum_discount_amount: 0,
//   sum_taxable_amount: 0,
// };

export const defaultAnalyticsState: IAnalyticsState = {
  analytics: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
