import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { LedgerActions } from ".";
import {
  CLEAR_LEDGER_STATE,
  FETCH_LEDGER_LIST_FAILED,
  FETCH_LEDGER_LIST_PROGRESS,
  FETCH_LEDGER_LIST_SUCCESS,
} from "./ledgerActions";
import { defaultLedgerState } from "./defaultState";

export const ledgerReducer = (
  state: IStoreState["ledger"] = defaultLedgerState,
  action: LedgerActions,
) => {
  switch (action.type) {
    case FETCH_LEDGER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.ledger.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_LEDGER_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ledger.loading = LoadState.Loaded;
        draftState.ledger.data = data;
        draftState.ledger.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_LEDGER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.ledger.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_LEDGER_STATE: {
      return defaultLedgerState;
    }
    default: {
      return state;
    }
  }
};
