import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_GRN_STATE,
  CLEAR_SINGLE_GRN,
  defaultGoodsReceivingNoteState,
  FETCH_GRN_LIST_FAILED,
  FETCH_GRN_LIST_PROGRESS,
  FETCH_GRN_LIST_SUCCESS,
  FETCH_SINGLE_GRN_FAILED,
  FETCH_SINGLE_GRN_PROGRESS,
  FETCH_SINGLE_GRN_SUCCESS,
  IGoodsReceivingNoteActions,
} from ".";

export const goodsReceivingNoteReducer = (
  state: IStoreState["grn"] = defaultGoodsReceivingNoteState,
  action: IGoodsReceivingNoteActions,
) => {
  switch (action.type) {
    case FETCH_GRN_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.grn_list.loading = LoadState.InProgress;
        draftState.grn_list.data = [];
        draftState.grn_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_GRN_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.grn_list.loading = LoadState.Loaded;
        draftState.grn_list.data = list;
        draftState.grn_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_GRN_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.grn_list.loading = LoadState.InProgress;
        draftState.grn_list.data = [];
        draftState.grn_list.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_SINGLE_GRN_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_grn.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_GRN_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_grn.loading = LoadState.Loaded;
        draftState.single_grn.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_GRN_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_grn.loading = LoadState.Failed;
        draftState.single_grn.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_GRN: {
      const newState = produce(state, (draftState) => {
        draftState.single_grn.loading = LoadState.NotLoaded;
        draftState.single_grn.data =
          defaultGoodsReceivingNoteState["single_grn"].data;
        draftState.single_grn.error = null;
      });
      return newState;
    }
    case CLEAR_GRN_STATE: {
      return defaultGoodsReceivingNoteState;
    }
    default: {
      return state;
    }
  }
};
