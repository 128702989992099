import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../../api/api";
import { IStoreState } from "../../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IApproval,
  IApprovalQueryParams,
  IUpsertApproval,
} from "./approvals.types";
import { getApprovalSearchQuery } from "./approvals.helpers";
import {
  APPROVALS_GET_APPROVAL,
  APPROVALS_UPSERT_APPROVAL,
} from "./approvals.routes";

export const FETCH_APPROVAL_LIST_PROGRESS = "FETCH_APPROVAL_LIST_PROGRESS";
export const FETCH_APPROVAL_LIST_SUCCESS = "FETCH_APPROVAL_LIST_SUCCESS";
export const FETCH_APPROVAL_LIST_FAILED = "FETCH_APPROVAL_LIST_FAILED";

export const fetchApprovalListProgress = () =>
  action(FETCH_APPROVAL_LIST_PROGRESS);
export const fetchApprovalQuotesListSuccess = (
  list: IApproval[],
  totalRecords: number,
) => action(FETCH_APPROVAL_LIST_SUCCESS, { list, totalRecords });
export const fetchApprovalQuotesListFailed = () =>
  action(FETCH_APPROVAL_LIST_FAILED);

export const fetchApprovalsListAsync =
  (
    queryParams: IApprovalQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const searchQuery = getApprovalSearchQuery(queryParams);

    try {
      dispatch(fetchApprovalListProgress());
      let finalUrl = `${APPROVALS_GET_APPROVAL}?table_name=latest_order&pageNo=${searchQuery}`;

      const res = await api.get(finalUrl);
      const data: IApproval[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchApprovalQuotesListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovalQuotesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// export const handleEnquiryOrderApprovalAsync =
//   (
//     data: IPostEnquiryOrderApproval,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(saveLoaderProgress());
//       await api.post("/enquiry/approve-order", data);
//       let message = "Action success!";
//       dispatch(
//         showMessage({
//           type: "success",
//           displayAs: "snackbar",
//           message: message,
//         }),
//       );
//       onCallback(true);
//     } catch (err: any) {
//       onCallback(true);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     } finally {
//       dispatch(saveLoaderCompleted());
//     }
//   };

export const upsertRecordApprovalAsync =
  (
    payload: IUpsertApproval,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const { create_ts, insert_ts, ...restPayload } = payload;
    try {
      dispatch(saveLoaderProgress());
      await api.post(APPROVALS_UPSERT_APPROVAL, restPayload);
      dispatch(
        showMessage({
          type: "success",
          message: "Approval raised successfully.",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_APPROVALS_STATE = "CLEAR_APPROVALS_STATE";
export const clearApprovalsState = () => action(CLEAR_APPROVALS_STATE);
