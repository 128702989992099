import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  defaultInvoiceExport,
  defaultInvoiceExportState,
} from "./defaultState";
import {
  FETCH_INVOICE_EXPORT_LIST_PROGRESS,
  FETCH_INVOICE_EXPORT_LIST_SUCCESS,
  FETCH_INVOICE_EXPORT_LIST_FAILED,
  FETCH_INVOICE_EXPORT_PROGRESS,
  FETCH_INVOICE_EXPORT_SUCCESS,
  FETCH_INVOICE_EXPORT_FAILED,
  CLEAR_INVOICE_EXPORT,
  CLEAR_INVOICE_EXPORT_STATE,
} from "./invoiceExportActions";
import { InvoiceExportAcions } from ".";

export const invoiceExportReducer = (
  state: IStoreState["invoiceExport"] = defaultInvoiceExportState,
  action: InvoiceExportAcions,
) => {
  switch (action.type) {
    case FETCH_INVOICE_EXPORT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_INVOICE_EXPORT_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_INVOICE_EXPORT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_INVOICE_EXPORT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.invoiceExport.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_INVOICE_EXPORT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.invoiceExport.loading = LoadState.Loaded;
        draftState.invoiceExport.data = data;
      });
      return newState;
    }
    case FETCH_INVOICE_EXPORT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.invoiceExport.loading = LoadState.Failed;
        draftState.invoiceExport.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_INVOICE_EXPORT: {
      const newState = produce(state, (draftState) => {
        draftState.invoiceExport.loading = LoadState.NotLoaded;
        draftState.invoiceExport.data = defaultInvoiceExport;
      });
      return newState;
    }
    case CLEAR_INVOICE_EXPORT_STATE: {
      return defaultInvoiceExportState;
    }
    default: {
      return state;
    }
  }
};
