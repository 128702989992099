import { action } from "typesafe-actions";
import { IQueryParams } from "../common/common.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getSearchQuery } from "../common/helpers";
import { fetchProductsListProgress } from "../products";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IInvoiceExport } from "./invoiceExport.types";

export const FETCH_INVOICE_EXPORT_LIST_PROGRESS =
  "FETCH_INVOICE_EXPORT_LIST_PROGRESS";
export const FETCH_INVOICE_EXPORT_LIST_SUCCESS =
  "FETCH_INVOICE_EXPORT_LIST_SUCCESS";
export const FETCH_INVOICE_EXPORT_LIST_FAILED =
  "FETCH_INVOICE_EXPORT_LIST_FAILED";

export const fetchInvoiceExportListProgress = () =>
  action(FETCH_INVOICE_EXPORT_LIST_PROGRESS);
export const fetchInvoiceExportListSuccess = (
  data: IInvoiceExport[],
  totalRecords: number,
) => action(FETCH_INVOICE_EXPORT_LIST_SUCCESS, { data, totalRecords });
export const fetchInvoiceExportListFailed = () =>
  action(FETCH_INVOICE_EXPORT_LIST_FAILED);

export const fetchInvoiceExportListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchInvoiceExportListProgress());
      let finalUrl = `/invoice/get-invoice-export-data${searchQuery}`;
      const res = await api.get(finalUrl);
      const data: IInvoiceExport[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchInvoiceExportListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchInvoiceExportListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertInvoiceExportAsync =
  (
    billingCompany: IInvoiceExport,
    onCallback: (isSuccess: boolean, billingCompany?: IInvoiceExport) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...rest } = billingCompany;
      const res = await api.post("/invoice/upsert-invoice-export-data", rest);
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Invoice Data saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_INVOICE_EXPORT_PROGRESS = "FETCH_INVOICE_EXPORT_PROGRESS";
export const FETCH_INVOICE_EXPORT_SUCCESS = "FETCH_INVOICE_EXPORT_SUCCESS";
export const FETCH_INVOICE_EXPORT_FAILED = "FETCH_INVOICE_EXPORT_FAILED";

export const fetchInvoiceExportProgress = () =>
  action(FETCH_INVOICE_EXPORT_PROGRESS);
export const fetchInvoiceExportSuccess = (data: IInvoiceExport) =>
  action(FETCH_INVOICE_EXPORT_SUCCESS, { data });
export const fetchInvoiceExportFailed = (errorMessage: string) =>
  action(FETCH_INVOICE_EXPORT_FAILED, { errorMessage });

export const fetchInvoiceExportAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInvoiceExportProgress());
      const res = await api.get(
        `/invoice/get-invoice-export-data?invoice_uuid=${uuid}`,
      );
      const data: IInvoiceExport[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchInvoiceExportSuccess(data[0]));
      } else {
        dispatch(
          fetchInvoiceExportFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchInvoiceExportFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_INVOICE_EXPORT = "CLEAR_INVOICE_EXPORT";
export const CLEAR_INVOICE_EXPORT_STATE = "CLEAR_INVOICE_EXPORT_STATE";

export const clearInvoiceExport = () => action(CLEAR_INVOICE_EXPORT);
export const clearInvoiceExportState = () => action(CLEAR_INVOICE_EXPORT_STATE);
