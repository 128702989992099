import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { CLEAR_VENDOR, CLEAR_VENDOR_STATE, FETCH_VENDOR_FAILED, FETCH_VENDOR_LIST_FAILED, FETCH_VENDOR_LIST_PROGRESS, FETCH_VENDOR_LIST_SUCCESS, FETCH_VENDOR_PROGRESS, FETCH_VENDOR_SUCCESS, initialVendor, initialVendorState, VendorAction } from ".";


export const vendorReducer = (
  state: IStoreState["vendor"] = initialVendorState,
  action: VendorAction,
) => {
  switch (action.type) {
    case FETCH_VENDOR_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_VENDOR_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_VENDOR_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_VENDOR_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.vendor.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_VENDOR_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.vendor.loading = LoadState.Loaded;
        draftState.vendor.data = data;
      });
      return newState;
    }
    case FETCH_VENDOR_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.vendor.loading = LoadState.Failed;
        draftState.vendor.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_VENDOR: {
      const newState = produce(state, (draftState) => {
        draftState.vendor.loading = LoadState.NotLoaded;
        draftState.vendor.data = initialVendor;
      });
      return newState;
    }
    case CLEAR_VENDOR_STATE: {
      return initialVendorState;
    }
    default: {
      return state;
    }
  }
};
