import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { getSearchQuery } from "../common/helpers";
import { IQueryParams } from "../common/common.types";
import {
  API_GET_PROJECT_ROUTE,
  API_UPSERT_PROJECT_ROUTE,
} from "./projects.routes";
import { IProject } from "./projects.types";

//=============================== Goods Receiving Note List ===============================
export const FETCH_PROJECT_LIST_PROGRESS = "FETCH_PROJECT_LIST_PROGRESS";
export const FETCH_PROJECT_LIST_SUCCESS = "FETCH_PROJECT_LIST_SUCCESS";
export const FETCH_PROJECT_LIST_FAILED = "FETCH_PROJECT_LIST_FAILED";

export const fetchProjectListProgress = () =>
  action(FETCH_PROJECT_LIST_PROGRESS);
export const fetchProjectListSuccess = (
  list: IProject[],
  totalRecords: number,
) => action(FETCH_PROJECT_LIST_SUCCESS, { list, totalRecords });
export const fetchProjectListFailed = () => action(FETCH_PROJECT_LIST_FAILED);

export const fetchProjectListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchProjectListProgress());

      const res = await api.get(
        `${API_GET_PROJECT_ROUTE}${searchQuery}&status=ACTIVE`,
      );
      const data: IProject[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchProjectListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchProjectListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Goods Receiving Note ===============================
export const FETCH_SINGLE_PROJECT_PROGRESS = "FETCH_SINGLE_PROJECT_PROGRESS";
export const FETCH_SINGLE_PROJECT_SUCCESS = "FETCH_SINGLE_PROJECT_SUCCESS";
export const FETCH_SINGLE_PROJECT_FAILED = "FETCH_SINGLE_PROJECT_FAILED";

export const fetchSingleProjectProgress = () =>
  action(FETCH_SINGLE_PROJECT_PROGRESS);
export const fetchSingleProjectSuccess = (data: IProject) =>
  action(FETCH_SINGLE_PROJECT_SUCCESS, { data });
export const fetchSingleProjectFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_PROJECT_FAILED, { errorMessage });

export const fetchSingleProjectAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleProjectProgress());
      const res = await api.get(
        `${API_GET_PROJECT_ROUTE}?quotation_uuid=${uuid}`,
      );
      const data: IProject[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleProjectSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleProjectFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleProjectFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Single Goods Receiving Note ===============================
export const upsertSingleProjectAsync =
  (
    payload: IProject,
    onCallback: (isSuccess: boolean, grnUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const { create_ts, insert_ts, combined_project_code, ...restPayload } =
        payload;
      const res = await api.post(API_UPSERT_PROJECT_ROUTE, restPayload);

      const responseData: IProject = res.data.data;

      onCallback(true, responseData.project_uuid as string);
      dispatch(
        showMessage({
          type: "success",
          message: "Quotation saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Clear State ===============================
export const CLEAR_SINGLE_PROJECT = "CLEAR_SINGLE_PROJECT";
export const CLEAR_PROJECT_STATE = "CLEAR_PROJECT_STATE";
export const clearSingleProjectSync = () => action(CLEAR_SINGLE_PROJECT);
export const clearProjectStateSync = () => action(CLEAR_PROJECT_STATE);
