import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { ILedger } from "./ledger.types";

//=============================== Ledger List ===============================

export const FETCH_LEDGER_LIST_PROGRESS = "FETCH_LEDGER_LIST_PROGRESS";
export const FETCH_LEDGER_LIST_SUCCESS = "FETCH_LEDGER_LIST_SUCCESS";
export const FETCH_LEDGER_LIST_FAILED = "FETCH_LEDGER_LIST_FAILED";

export const fetchLedgerListProgress = () => action(FETCH_LEDGER_LIST_PROGRESS);
export const fetchLedgerListSuccess = (data: ILedger[], totalRecords: number) =>
  action(FETCH_LEDGER_LIST_SUCCESS, { data, totalRecords });
export const fetchLedgerListFailed = () => action(FETCH_LEDGER_LIST_FAILED);

export const fetchLedgerListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchLedgerListProgress());
        const res = await api.get(`ledger/get-ledger${searchQuery}`);
        const data: ILedger[] = res.data.data;

        const totalRecords = res.data.totalRecords;

        dispatch(fetchLedgerListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchLedgerListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

//================ Product Ledger List =================

export const fetchProductLedgerListProgress = () => action(FETCH_LEDGER_LIST_PROGRESS);
export const fetchProductLedgerListSuccess = (data: ILedger[], totalRecords: number) =>
  action(FETCH_LEDGER_LIST_SUCCESS, { data, totalRecords });
export const fetchProductLedgerListFailed = () => action(FETCH_LEDGER_LIST_FAILED);

export const fetchProductLedgerListAsync =
  (queryParams: IQueryParams, productUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchLedgerListProgress());
        const res = await api.get(`ledger/get-ledger${searchQuery}&product_uuid=${productUUID}`);
        const data: ILedger[] = res.data.data;

        const totalRecords = res.data.totalRecords;

        dispatch(fetchLedgerListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchLedgerListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_LEDGER_STATE = "CLEAR_LEDGER_STATE";
export const clearLedgerState = () => action(CLEAR_LEDGER_STATE);
