import { LoadState } from "../../../constants/enums";
import { IApprovalState } from "./approvals.types";

export const defaultApprovalState: IApprovalState = {
  approvals_list: {
    data: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
  },
};
