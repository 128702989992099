import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  CLEAR_ANALYTICS_STATE,
  FETCH_ANALYTICS_LIST_FAILED,
  FETCH_ANALYTICS_LIST_PROGRESS,
  FETCH_ANALYTICS_LIST_SUCCESS,
} from "./analyticsActions";
import { defaultAnalyticsState } from "./defaultState";
import { AnalyticsAcions } from ".";

export const analyticsReducer = (
  state: IStoreState["analytics"] = defaultAnalyticsState,
  action: AnalyticsAcions,
) => {
  switch (action.type) {
    case FETCH_ANALYTICS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.analytics.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ANALYTICS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.analytics.loading = LoadState.Loaded;
        draftState.analytics.data = data;
        draftState.analytics.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ANALYTICS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.analytics.loading = LoadState.InProgress;
      });
      return newState;
    }

    case CLEAR_ANALYTICS_STATE: {
      return defaultAnalyticsState;
    }
    default: {
      return state;
    }
  }
};
