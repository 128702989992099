import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ICustomerDeliveryAddress } from "./customDeliveryAddress.types";


export const FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS =
  "FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS";
export const FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS =
  "FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS";
export const FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED =
  "FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED";

export const fetchCustomerDeliveryAddressListProgress = () =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS);
export const fetchCustomerDeliveryAddressListSuccess = (
  data: ICustomerDeliveryAddress[],
  totalRecords: number,
) => action(FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS, { data, totalRecords });
export const fetchCustomerDeliveryAddressListFailed = () =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED);

export const fetchCustomerDeliveryAddressListAsync =
  (queryParams: IQueryParams, customerUUID?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchCustomerDeliveryAddressListProgress());
        let finalUrl = `/customer/get-customer-delivery-address${searchQuery}&customer_uuid=${customerUUID}`;
        const res = await api.get(finalUrl);
        const data: ICustomerDeliveryAddress[] = res.data.data;
        const totalRecords = res.data.totalRecords;
        dispatch(fetchCustomerDeliveryAddressListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchCustomerDeliveryAddressListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertCustomerDeliveryAddressAsync =
  (
    customerDeliveryAddress: ICustomerDeliveryAddress,
    onCallback: (isSuccess: boolean, customerDeliveryAddress?: ICustomerDeliveryAddress) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        const { create_ts, insert_ts, rowId, ...rest } = customerDeliveryAddress;
        const res = await api.post(
          "/customer/upsert-customer-delivery-address",
          rest,
        );
        const data = res.data.data;
        onCallback(true, data);
        dispatch(
          showMessage({
            type: "success",
            message: "Customer DeliveryAddress saved successfully!",
            displayAs: "snackbar",
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS = "FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS";
export const FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS = "FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS";
export const FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED = "FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED";

export const fetchCustomerDeliveryAddressProgress = () =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS);
export const fetchCustomerDeliveryAddressSuccess = (data: ICustomerDeliveryAddress) =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS, { data });
export const fetchCustomerDeliveryAddressFailed = (errorMessage: string) =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED, { errorMessage });

export const fetchCustomerDeliveryAddressAsync =
  (customerDeliveryAddressUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchCustomerDeliveryAddressProgress());
        const res = await api.get(
          `/customer/get-customer-delivery-address?customer_DELILVERY_ADDRESS_uuid=${customerDeliveryAddressUUID}`,
        );
        const data: ICustomerDeliveryAddress[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchCustomerDeliveryAddressSuccess(data[0]));
        } else {
          dispatch(
            fetchCustomerDeliveryAddressFailed("Oops! We couldn't find any records."),
          );
        }
      } catch (err: any) {
        dispatch(fetchCustomerDeliveryAddressFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_CUSTOMER_DELIVERY_ADDRESS = "CLEAR_CUSTOMER_DELIVERY_ADDRESS";
export const CLEAR_CUSTOMER_DELILVERY_ADDRESS_STATE = "CLEAR_CUSTOMER_DELILVERY_ADDRESS_STATE";

export const clearCustomerDeliveryAddress = () => action(CLEAR_CUSTOMER_DELIVERY_ADDRESS);
export const clearCustomerDeliveryAddressState = () =>
  action(CLEAR_CUSTOMER_DELILVERY_ADDRESS_STATE);
