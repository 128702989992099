import { IMessagesState } from "./messages/messages.types";
import { initialMessagesState } from "./messages/messagesState";
import { ICustomizerState } from "./customizer/customizerReducer";
import { ICustomerState } from "./customer/customer.types";
// import { IAutomobilePolicyRiskLocationState, defaultAutomobilePolicyRiskLocationState } from "./automobilePolicyRiskLocation/automobilePolicyRiskLocation.types";
import { ICommonState, defaultCommonState } from "./common/common.types";

import { IUsersState } from "./UserProfileList/userProfile.types";
import { initialUserProfileState } from "./UserProfileList/userprofileState";

import {
  IOpportunityState,
  inititalOpportunityState,
} from "./opportunites/opportunites.types";
import { ICategoryState } from "./category/category.types";
import { defaultCustomerState } from "./customer/defaultState";
import { IContactsState, initialContactState } from "./contacts/contacts.types";
import { IProductState } from "./products/products.types";
import {
  ITaskBoardState,
  initialTaskBoardState,
} from "./taskBoard/taskBoard.types";
import { IRenderingState } from "./rendering/rendering.types";
import { defaultRenderingState } from "./rendering/defaultState";
import { defaultProductState } from "./products/defaultState";
import { defaultCategoryState } from "./category/defaultState";
import { ILedgerState } from "./ledger/ledger.types";
import { defaultLedgerState } from "./ledger/defaultState";
import { defaultInventoryState } from "./inventory/defaultState";
import { defaultInvoiceState, IInvoiceState } from "./invoice";
import { IInventoryState } from "./inventory/inventory.types";
import { IBillingCompanyState } from "./billingCompany";
import { defaultBillingCompanyState } from "./billingCompany/defaultState";
import { IBankDetailsState } from "./bankDetails/bankDetails.types";
import { initialBankDetailsState } from "./bankDetails/defaultState";
import { initialVendorState, IVendorState } from "./vendor";
import {
  ICustomerBranchState,
  initialCustomerBranchState,
} from "./customerBranch";
import { ICreditNoteState, defaultCreditNoteState } from "./creditNote";
import {
  IProformaInvoiceState,
  defaultProformaInvoiceState,
} from "./proformaInvoice";
import {
  ICustomerDeliveryAddressState,
  initialCustomerDeliveryAddressState,
} from "./customerDeliveryAddress";
import {
  defaultDeliveryChallanState,
  IDeliveryChallanState,
} from "./deliveryChallan";
import {
  ICustomerDispatchAddressState,
  initialCustomerDispatchAddressState,
} from "./customerDispatchAddress";
import {
  defaultInvoiceExportState,
  IInvoiceExportState,
} from "./invoiceExport";
import {
  defaultCustomerVendorAttachment,
  defaultCustomerVendorAttachmentState,
  ICustomerVendorAttachmentState,
} from "./customerVendorAttachment";
import { defaultAnalyticsState, IAnalyticsState } from "./analytics";
import {
  defaultGoodsReceivingNoteState,
  IGoodsReceivingNoteState,
} from "./goods-receiving-note";
import {
  defaultPurchaseOrderState,
  IPurchaseOrderState,
} from "./purchase-order";
import { defaultSaleOrderState, ISaleOrderState } from "./sale-order";
import { defaultQuotationState, IQuotationState } from "./quotation";
import {
  defaultSecurityState,
  ISecurityState,
} from "./security/security.types";
import { defaultApprovalState, IApprovalState } from "./approvals/approvals";
import { defaultProjectState, IProjectState } from "./projects";

export interface IDropdownListType {
  key: string;
  text: string;
  value: string;
}

export interface IStoreState {
  customizerReducer: ICustomizerState;
  customer: ICustomerState;
  message: IMessagesState;
  userprofile: IUsersState;
  common: ICommonState;
  opportunites: IOpportunityState;
  category: ICategoryState;
  contacts: IContactsState;
  product: IProductState;
  invoices: IInvoiceState;
  deliveryChallan: IDeliveryChallanState;
  taskBoard: ITaskBoardState;
  rendering: IRenderingState;
  ledger: ILedgerState;
  inventory: IInventoryState;
  billingCompany: IBillingCompanyState;
  analytics: IAnalyticsState;
  invoiceExport: IInvoiceExportState;
  bankDetails: IBankDetailsState;
  vendor: IVendorState;
  customerBranch: ICustomerBranchState;
  customerDeliveryAddress: ICustomerDeliveryAddressState;
  customerDispatchAddress: ICustomerDispatchAddressState;
  customerVendorAttachment: ICustomerVendorAttachmentState;
  creditNote: ICreditNoteState;
  proformaInvoice: IProformaInvoiceState;
  grn: IGoodsReceivingNoteState;
  purchase_order: IPurchaseOrderState;
  sale_order: ISaleOrderState;
  quotation: IQuotationState;
  security: ISecurityState;
  approvals: IApprovalState;
  project: IProjectState;
}

export const initialStoreState: IStoreState = {
  customizerReducer: {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "INDIGO_THEME", //BLUE_THEME, GREEN_THEME,RED_THEME,BLACK_THEME,PURPLE_THEME,INDIGO_THEME,ORANGE_THEME,LIGHT_BLUE_THEME,MAROON_THEME,ROCKWORTH_THEME
    SidebarWidth: 240,
    sidebarOpen: true,
  },

  customer: defaultCustomerState,
  message: initialMessagesState,

  common: defaultCommonState,

  userprofile: initialUserProfileState,

  opportunites: inititalOpportunityState,
  category: defaultCategoryState,
  contacts: initialContactState,
  product: defaultProductState,
  invoices: defaultInvoiceState,
  deliveryChallan: defaultDeliveryChallanState,
  taskBoard: initialTaskBoardState,
  rendering: defaultRenderingState,
  ledger: defaultLedgerState,
  inventory: defaultInventoryState,
  analytics: defaultAnalyticsState,
  billingCompany: defaultBillingCompanyState,
  invoiceExport: defaultInvoiceExportState,
  bankDetails: initialBankDetailsState,
  vendor: initialVendorState,
  customerBranch: initialCustomerBranchState,
  customerDeliveryAddress: initialCustomerDeliveryAddressState,
  customerDispatchAddress: initialCustomerDispatchAddressState,
  customerVendorAttachment: defaultCustomerVendorAttachmentState,
  creditNote: defaultCreditNoteState,
  proformaInvoice: defaultProformaInvoiceState,
  grn: defaultGoodsReceivingNoteState,
  purchase_order: defaultPurchaseOrderState,
  sale_order: defaultSaleOrderState,
  quotation: defaultQuotationState,
  security: defaultSecurityState,
  approvals: defaultApprovalState,
  project: defaultProjectState,
};
