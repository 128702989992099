import { api } from "../api/api";
import { IFileUpload } from "../components/FileUpload/FileUpload.type";

export const uploadFile = async (
  file: File | null,
  module_name: string,
  defaultReturn: string,
  asPayload: {
    [key: string]: string | number | null;
  },
) => {
  if (file) {
    const formdata = new FormData();
    formdata.append("files", file);
    formdata.append("module_name", module_name);
    formdata.append("as_payload", JSON.stringify(asPayload));
    const res = await api.post("/general/upload-files", formdata);
    const data: string[] = res.data.data;
    if (data.length > 0) {
      return data[0];
    }
  }
  return defaultReturn;
};

export const uploadMultipleFiles = async (
  files: IFileUpload[],
  module_name: string,
  asPayload: { [key: string]: string | number | null },
): Promise<string[]> => {
  const existingFiles: string[] = [];
  let noFileToUpload: boolean = true;
  const formdata = new FormData();
  for (const file of files) {
    if (file.path) {
      existingFiles.push(file.path);
    } else {
      noFileToUpload = false;
      formdata.append("files", file.file as File); // appending every file to formdata
    }
  }

  if (noFileToUpload) {
    return existingFiles;
  }

  formdata.append("module_name", module_name);
  formdata.append("as_payload", JSON.stringify(asPayload));
  const result = await api.post("/general/upload-files", formdata);
  existingFiles.concat(result.data.data as string[]);
  return existingFiles;
};
