export enum LoadState {
  NotLoaded = 0,
  InProgress = 1,
  Loaded = 2,
  Failed = 3,
}

export enum SaveState {
  NotStarted = 0,
  Progress = 1,
  Saved = 2,
}

export enum USER_ROLES {
  ADMIN = 1,
  CSR = 2,
  PRODUCER = 3,
  MARKETER = 4,
  BRANCH_MANAGER = 5,
}

export enum TASK_SUBTYPE {
  VEHICLE_CHANGE = "Vehicle Change",
  DRIVER_CHANGE = "Driver Change",
  COVERAGE_CHANGE = "Coverage Change",
  GARAGE_PLATE_CHANGE = "Garage Plate Change",
  INTERESTED_PARTY_CHANGE = "Interested Party Change",
  LESSOR_CHANGE = "Lessor Change",
  ADDRESS_CHANGE = "Address Change",
  RISK_LOCATION_CHANGE = "Risk Location Change",
  CANCELLATION_REINSTALLMENT = "Cancellation/Reinstatement",
  BUSINESS_SUBMISSION = "Business Submission",
  GENERAL_CHANGE = "General Change",
  PAYMENT_AUTH_CHANGE = "Payment Authorization Change",
  LAO_BOR = "LAO/BOR",
  NEW_BUSINESS = "Business",
  NEW_PERSONAL_AUTO = "Personal Auto",
  NEW_HOME = "Home",
  NEW_COMMERCIAL_AUTO = "Commercial Auto",
  REMARKET_COMMERCIAL_AUTO = "Commercial Auto",
  REMARKET_HOME = "Home",
  REMARKET_BUSINESS = "Business",
  REMARKET_PERSONAL_AUTO = "Personal Auto",
}

export enum POLICIES {
  BUSINESS_POLICY = "Business Policy",
  AUTOMOBILE_POLICY = "Automobile Policy",
  HABITATIONAL_POLICY = "Habitational Policy",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto Policy",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Fincancial Policy",
}

export enum POLICIES_NAMES {
  BUSINESS_POLICY = "Business",
  AUTOMOBILE_POLICY = "Automobile",
  HABITATIONAL_POLICY = "Habitational",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Fincancial",
}

export enum ERROR_MESSAGES {
  "SERVER_ERROR" = "Someting went to be wrong!",
  "POLICY_NOT_FOUND" = "Oops! We couldn't find any records at the moment. Please ensure that the provided information is accurate and try again.",
}

export enum ALLOWED_MODULES {
  BANK_DETAILS = "ENTITIES|BANK DETAILS|LATEST_BANK_DETAILS",
  CONTACTS = "ENTITIES|CONTACTS|LATEST_CONTACTS",
  CUSTOMERS = "ENTITIES|CUSTOMERS|LATEST_CUSTOMER",
  CUSTOMER_ATTACHMENT = "ENTITIES|CUSTOMER ATTACHMENT|LATEST_CUSTOMER_ATTACHMENT",
  CUSTOMER_BRANCH = "ENTITIES|CUSTOMER BRANCH|LATEST_CUSTOMER_BRANCH",
  CUSTOMER_DELIVERY_ADDRESS = "ENTITIES|CUSTOMER DELIVERY ADDRESS|LATEST_CUSTOMER_DELIVERY_ADDRESS",
  CUSTOMER_DISPATCH_ADDRESS = "ENTITIES|CUSTOMER DISPATCH ADDRESS|LATEST_CUSTOMER_DISPATCH_ADDRESS",

  CATEGORY = "CATEGORY|CATEGORY|LATEST_CATEGORY",
  CREDIT_DEBIT_NOTE = "CREDIT DEBIT NOTE|CREDIT DEBIT NOTE|LATEST_CREDIT_DEBIT_NOTE",
  DELIVERY_CHALLAN = "DELIVERY CHALLAN|DELIVERY CHALLAN|LATEST_DELIVERY_CHALLAN",
  INVENTORY = "INVENTORY|INVENTORY|LATEST_INVENTORY",
  INVOICE_EXPORT_DATA = "INVOICE|INVOICE EXPORT DATA|LATEST_INVOICE_EXPORT_DATA",
  INVOICE = "INVOICE|INVOICE|LATEST_INVOICES",
  LEDGER = "LEDGER|LEDGER|LATEST_LEDGER",
  PRODUCTS = "PRODUCTS|PRODUCTS|LATEST_PRODUCT",
  PROFORMA_INVOICE = "PROFORMA INVOICE|PROFORMA INVOICE|LATEST_PROFORMA_INVOICES",
  VENDOR = "VENDOR|VENDOR|LATEST_VENDORS",
  USERS = "USERS|USERS|LATEST_USER",
  SECURITY = "SECURITY|SECURITY|ROLE_MODULE",
  ROLE_GROUPS = "SECURITY|ROLE GROUPS|LATEST_ROLE_GROUPS",
  QUOTATION = "QUOTATION|QUOTATION|LATEST_QUOTATION",
  PURCHASE_ORDER = "PURCHASE ORDER|PURCHASE ORDER|LATEST_PURCHASE_ORDER",
  SALE_ORDER = "SALE ORDER|SALE ORDER|LATEST_SALE_ORDER",
  GRN = "GRN|GRN|LATEST_GRN",

  ROLES = "latest_roles",
  PROJECT = "PROJECT|PROJECT|LATEST_PROJECT",
}
