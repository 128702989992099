import { LoadState } from "../../constants/enums";
import { ISecurityRoleGroup } from "./role-group/role-group.types";

export interface ISecurityState {
  groups: {
    roleName: string | null;
    status: string | null;
    group: ISecurityNestedGroup;
    role_group: string | null;
    loading: LoadState;
    error: string | null;
  };
  roles: {
    list: IRole[];
    totalRecords: number;
    loading: LoadState;
  };
  approval: {
    list: ISecurityApproval[];
    totalRecords: number;
    loading: LoadState;
    approvalData: ISecurityApproval;
    approvalLoading: LoadState;
    error: string | null;
  };
  roleGroups: {
    list: ISecurityRoleGroup[];
    totalRecords: number;
    loading: LoadState;
  };
}

export interface ISecurityApproval {
  approval_count_uuid: string | null;
  table_name: string;
  link_table: string | null;
  link_column: string | null;
  approval_hierarchy: { type: string; uuid: string }[][];
  status: "ACTIVE" | "INACTIVE";
  approval_raise_status: string;
  previous_status: string;
  next_status: string;
  create_ts?: string;
  level?: string;
  insert_ts?: string;
}

export interface IRole {
  role_id: number;
  role_uuid: string;
  role_name: string;
  role_json: string;
  status: string;
}

export interface ISecurityGroup extends IRecordPremission {
  module_id: string;
  module_name: string;
  module_key: string;
  submodule_name: string;
  role_name: string;
  role_id: number;
  role_uuid: number;
  show_module: number;
  view_access: number;
  edit_access: number;
  bulk_import: number;
  bulk_export: number;
  send_sms: number;
  send_mail: number;
  send_whatsapp: number;
  send_call: number;
}
export interface ISecurityNestedGroup {
  modules: {
    [key: string]: ISecurityGroupWithChildren;
  };
}

export interface ISecurityGroupWithChildren {
  children: ISecurityGroup[];
}

export interface IRecordPremission {
  module_id: string;
  role_id: number;
  role_uuid: number;
  submodule_name: string;
  table_name: string;
  column_relation_options: IRecordColumnRelation[];
  filter_values: IRecordFilterValue;
  role_module_code?: string | null;
}

export interface IRecordColumnRelation {
  api: string;
  field: string;
  value: string;
  column_key: string;
  column_label: string;
}

export interface IRecordFilterValue {
  [key: string]: {
    [key: string]: any[];
  };
}

export const defaultSecurityState: ISecurityState = {
  groups: {
    roleName: null,
    status: "ACTIVE",
    group: { modules: {} },
    role_group: "",
    loading: LoadState.NotLoaded,
    error: null,
  },
  roles: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  approval: {
    list: [],
    loading: LoadState.NotLoaded,
    approvalData: {
      approval_count_uuid: null,
      table_name: "",
      link_table: null,
      link_column: null,
      approval_hierarchy: [[{ type: "", uuid: "" }]],
      status: "ACTIVE",
      approval_raise_status: "",
      previous_status: "",
      next_status: "",
    },
    approvalLoading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },
  roleGroups: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
};
