import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { IAnalyticModule, IAnalytics } from "./analytics.types";
import { get_module_name_path } from "./analytics.constants";

export const FETCH_ANALYTICS_LIST_PROGRESS = "FETCH_ANALYTICS_LIST_PROGRESS";
export const FETCH_ANALYTICS_LIST_SUCCESS = "FETCH_ANALYTICS_LIST_SUCCESS";
export const FETCH_ANALYTICS_LIST_FAILED = "FETCH_ANALYTICS_LIST_FAILED";

export const fetchInvoiceAnalyticsListProgress = () =>
  action(FETCH_ANALYTICS_LIST_PROGRESS);
export const fetchInvoiceAnalyticsListSuccess = (
  data: IAnalytics[],
  totalRecords: number,
) => action(FETCH_ANALYTICS_LIST_SUCCESS, { data, totalRecords });
export const fetchInvoiceAnalyticsListFailed = () =>
  action(FETCH_ANALYTICS_LIST_FAILED);

export const fetchModuleAnalyticsWithArgsAsync =
  ({
    module,
    from_data,
    to_date,
    cdn_type,
  }: {
    module: IAnalyticModule;
    from_data: string;
    to_date: string;
    cdn_type: "CREDIT" | "DEBIT" | null;
  }): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let module_path_url = get_module_name_path[module];
      let cdn_type_url = "";
      if (cdn_type) {
        cdn_type_url += `type=${cdn_type}&`;
      }

      dispatch(fetchInvoiceAnalyticsListProgress());
      let finalUrl = `/analytics/get-${module_path_url}-analytics?${cdn_type_url}from_date=${from_data}&to_date=${to_date}`;
      const res = await api.get(finalUrl);
      const data: IAnalytics[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchInvoiceAnalyticsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchInvoiceAnalyticsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_ANALYTICS_LIST = "CLEAR_ANALYTICS_LIST";
export const CLEAR_ANALYTICS_STATE = "CLEAR_ANALYTICS_STATE";

export const clearAnalyticsListSync = () => action(CLEAR_ANALYTICS_LIST);
export const clearAnalyticsCompanyState = () => action(CLEAR_ANALYTICS_STATE);
