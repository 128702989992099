import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialTaskBoardState } from "./taskBoard.types";
import { TaskBoardActions } from ".";
import { FETCH_TASKBOARD_FAILED, FETCH_TASKBOARD_PROGRESS, FETCH_TASKBOARD_SUCCESS } from "./taskBoardActions";

export const tasksBoardReducer = (
  state: IStoreState["taskBoard"] = initialTaskBoardState,
  action: TaskBoardActions
) => {
  switch (action.type) {
    case FETCH_TASKBOARD_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = initialTaskBoardState['data'];
      });
      return newState;
    }
    case FETCH_TASKBOARD_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_TASKBOARD_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
