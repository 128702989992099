import { LoadState } from "../../constants/enums";
import { IInvoice, IInvoiceItem, IInvoiceState } from "./invoice.types";

export const defaultInvoiceItem: IInvoiceItem = {
  product_uuid: "",
  product_name: "",
  customer_product_name: "",
  description: "",
  item_type: null,
  HSN_code: "",
  quantity: 0,
  unit: "",
  other_unit: "",
  unit_price: 0,
  discount: 0,
  discount_amount: 0,
  cess_rate: 0,
  cess_amount: 0,
  taxable_amount: 0,
  tax_rate: 0,
  tax_amount: 0,
  tax_type: "IGST",
  total: 0,
};

export const defaultInvoice: IInvoice = {
  customer_type: "",
  invoice_items: [defaultInvoiceItem],
  invoice_uuid: null,
  invoice_no: "",
  invoice_date: null,
  invoice_type: "Invoice",
  project_site: null,
  ack_no: null,
  ack_date: null,
  irn: null,
  supply_type_code: "B2B",
  eway_bill_no: null,
  eway_bill_date: null,
  valid_till_date: null,
  vehicle_no: null,
  payment_mode: null,
  document_type: "TAX INVOICE",
  remark: null,
  region: null,
  place_of_supply: null,
  billing_company_name: null,
  billing_company_uuid: "",
  billing_company_branch_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_state: null,
  customer_uuid: "",
  customer_name: "",
  customer_registration_type: null,
  customer_branch_uuid: "",
  customer_branch_name: "",
  customer_address_line1: null,
  customer_address_line2: null,
  customer_address_city: null,
  customer_address_state: null,
  customer_address_pincode: null,
  customer_gstin: "",
  customer_address_country: null,
  dispatch_address_line1: null,
  dispatch_address_line2: null,
  dispatch_address_city: null,
  dispatch_address_state: null,
  dispatch_address_pincode: null,
  dispatch_address_country: null,
  dispatch_through: null,
  transport_id: null,
  transport_doc_no: null,
  transport_doc_date: null,
  delivery_name: null,
  advising_bank_uuid: null,
  advising_bank_name: null,
  sales_type: "Hand Delivery",
  approved_flag: null,
  approved_by: null,
  total_amount: 0,
  is_round_off: 0,
  term_and_condition:
    "Subject to our home Jurisdiction. Our Responsibility Ceases as soon as goods leaves our Premises.Goods once sold will not be taken back. Delivery Ex-Premises.",
  total_amount_after_tax: 0,
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  po_number: null,
  po_date: null,
  challan_no: null,
  challan_date: null,
  lr_no: null,
  qr_code: "",
  status: "ACTIVE",
};

export const defaultInvoiceState: IInvoiceState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  invoice: {
    data: defaultInvoice,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
