import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { defaultCreditNote, defaultCreditNoteState } from "./defaultState";
import {
  CLEAR_CREDIT_NOTE_STATE,
  CLEAR_SINGLE_CREDIT_NOTE,
  FETCH_CREDIT_NOTE_LIST_FAILED,
  FETCH_CREDIT_NOTE_LIST_PROGRESS,
  FETCH_CREDIT_NOTE_LIST_SUCCESS,
  FETCH_SINGLE_CREDIT_NOTE_FAILED,
  FETCH_SINGLE_CREDIT_NOTE_PROGRESS,
  FETCH_SINGLE_CREDIT_NOTE_SUCCESS,
} from "./actions";
import { ICreditDebitNodeActions } from ".";

export const creditNoteReducer = (
  state: IStoreState["creditNote"] = defaultCreditNoteState,
  action: ICreditDebitNodeActions,
) => {
  switch (action.type) {
    case FETCH_CREDIT_NOTE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CREDIT_NOTE_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CREDIT_NOTE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_SINGLE_CREDIT_NOTE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.creditNote.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_CREDIT_NOTE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.creditNote.loading = LoadState.Loaded;
        draftState.creditNote.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_CREDIT_NOTE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.creditNote.loading = LoadState.Failed;
        draftState.creditNote.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_CREDIT_NOTE: {
      const newState = produce(state, (draftState) => {
        draftState.creditNote.loading = LoadState.NotLoaded;
        draftState.creditNote.data = defaultCreditNote;
        draftState.creditNote.error = null;
      });
      return newState;
    }
    case CLEAR_CREDIT_NOTE_STATE: {
      return defaultCreditNoteState;
    }
    default: {
      return state;
    }
  }
};
