import produce from "immer";
import { CLEAR_DELIVERY_CHALLAN, CLEAR_DELIVERY_CHALLAN_STATE, DeliveryChallanAcitons, FETCH_DELIVERY_CHALLAN_FAILED, FETCH_DELIVERY_CHALLAN_LIST_FAILED, FETCH_DELIVERY_CHALLAN_LIST_PROGRESS, FETCH_DELIVERY_CHALLAN_LIST_SUCCESS, FETCH_DELIVERY_CHALLAN_PROGRESS, FETCH_DELIVERY_CHALLAN_SUCCESS, defaultDeliveryChallanState } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";


export const deliveryChallanReducer = (
  state: IStoreState["deliveryChallan"] = defaultDeliveryChallanState,
  action: DeliveryChallanAcitons,
) => {
  switch (action.type) {
    case FETCH_DELIVERY_CHALLAN_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_DELIVERY_CHALLAN_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_DELIVERY_CHALLAN_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_DELIVERY_CHALLAN_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.deliveryChallan.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DELIVERY_CHALLAN_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.deliveryChallan.loading = LoadState.Loaded;
        draftState.deliveryChallan.data = data;
      });
      return newState;
    }
    case FETCH_DELIVERY_CHALLAN_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.deliveryChallan.loading = LoadState.Failed;
        draftState.deliveryChallan.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_DELIVERY_CHALLAN: {
      const newState = produce(state, (draftState) => {
        draftState.deliveryChallan.loading = LoadState.NotLoaded;
        draftState.deliveryChallan.data = defaultDeliveryChallanState["deliveryChallan"].data;
        draftState.deliveryChallan.error = null;
      });
      return newState;
    }
    case CLEAR_DELIVERY_CHALLAN_STATE: {
      return defaultDeliveryChallanState;
    }
    default: {
      return state;
    }
  }
};
