import produce from "immer";
import { CLEAR_CUSTOMER_BRANCH, CLEAR_CUSTOMER_BRANCH_STATE, CustomerBranchActions, FETCH_CUSTOMER_BRANCH_FAILED, FETCH_CUSTOMER_BRANCH_LIST_FAILED, FETCH_CUSTOMER_BRANCH_LIST_PROGRESS, FETCH_CUSTOMER_BRANCH_LIST_SUCCESS, FETCH_CUSTOMER_BRANCH_PROGRESS, FETCH_CUSTOMER_BRANCH_SUCCESS } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { initialCustomerBranch, initialCustomerBranchState } from "./defaultState";

export const customerBranchReducer = (
  state: IStoreState["customerBranch"] = initialCustomerBranchState,
  action: CustomerBranchActions,
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_BRANCH_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCH_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCH_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_CUSTOMER_BRANCH_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customBranchLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCH_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customBranchLoading = LoadState.Loaded;
        draftState.customerBranch = data;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCH_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customBranchLoading = LoadState.Failed;
        draftState.customBranchError = errorMessage;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_BRANCH: {
      const newState = produce(state, (draftState) => {
        draftState.customBranchLoading = LoadState.NotLoaded;
        draftState.customerBranch = initialCustomerBranch;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_BRANCH_STATE: {
      return initialCustomerBranchState;
    }
    default: {
      return state;
    }
  }
};
