import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { uploadFile } from "../../helpers/uploadFile";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IProduct } from "./products.types";

export const FETCH_PRODUCTS_LIST_PROGRESS = "FETCH_PRODUCTS_LIST_PROGRESS";
export const FETCH_PRODUCTS_LIST_SUCCESS = "FETCH_PRODUCTS_LIST_SUCCESS";
export const FETCH_PRODUCTS_LIST_FAILED = "FETCH_PRODUCTS_LIST_FAILED";

export const fetchProductsListProgress = () =>
  action(FETCH_PRODUCTS_LIST_PROGRESS);
export const fetchProductsListSuccess = (
  data: IProduct[],
  totalRecords: number,
) => action(FETCH_PRODUCTS_LIST_SUCCESS, { data, totalRecords });
export const fetchProductsListFailed = () => action(FETCH_PRODUCTS_LIST_FAILED);

export const fetchProductsListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchProductsListProgress());

      let finalUrl = `/product/get-product${searchQuery}`;
      const res = await api.get(finalUrl);
      const data: IProduct[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchProductsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchProductsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertProductsAsync =
  (
    product: IProduct,
    file: File | null,
    onCallback: (isSuccess: boolean, product?: IProduct) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let { create_ts, insert_ts, ...rest } = product;
      let payload = {
        ...rest,
        // image: "sfbsfvasjkfbak",
      };
      dispatch(saveLoaderProgress());
      if (file) {
        const path = await uploadFile(file, "PRODUCT", "", {});
        payload.image = path;
      }

      const res = await api.post("/product/upsert_product", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Product save successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_PRODUCT_PROGRESS = "FETCH_PRODUCT_PROGRESS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILED = "FETCH_PRODUCT_FAILED";

export const fetchProductProgress = () => action(FETCH_PRODUCT_PROGRESS);
export const fetchProductSuccess = (data: IProduct) =>
  action(FETCH_PRODUCT_SUCCESS, { data });
export const fetchProductFailed = (errorMessage: string) =>
  action(FETCH_PRODUCT_FAILED, { errorMessage });

export const fetchProductAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchProductProgress());
      const res = await api.get(`/product/get-product?product_uuid=${uuid}`);
      const data: IProduct[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchProductSuccess(data[0]));
      } else {
        dispatch(fetchProductFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchProductFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const CLEAR_PRODUCTS_STATE = "CLEAR_PRODUCTS_STATE";
export const clearProducts = () => action(CLEAR_PRODUCTS);
export const clearProduct = () => action(CLEAR_PRODUCT);
export const clearProductState = () => action(CLEAR_PRODUCTS_STATE);
