import { LoadState } from "../../constants/enums";
import { ILedger, ILedgerState } from "./ledger.types";

export const defaultLedger: ILedger = {
  ledger_uuid: null,
  selling_name: "",
  customer_name: "",
  quantity: 0,
  type: "NULL",
  balance: "",
  invoice_no: "",
  status: "ACTIVE",
};

export const defaultLedgerState: ILedgerState = {
  ledger: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
};
