import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { QUOTATIONS_ROUTE } from "./Quotations.constants";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ALLOWED_MODULES } from "../../constants/enums";

//************************ Purchase Order Imports ***************************/
const QuotationsTable = Loadable(
  lazy(() =>
    import("./QuotationsTable").then(({ QuotationsTable }) => ({
      default: QuotationsTable,
    })),
  ),
);

const ManageQuotation = Loadable(
  lazy(() =>
    import("./ManageQuotation").then(({ ManageQuotation }) => ({
      default: ManageQuotation,
    })),
  ),
);

//************************ Purchase Order Routes ***************************/
export const quotationsRoutes = [
  {
    path: QUOTATIONS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.QUOTATION]}>
        <QuotationsTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${QUOTATIONS_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.QUOTATION]}>
        <ManageQuotation />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${QUOTATIONS_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.QUOTATION]}>
        <ManageQuotation />
      </AuthorizedRoute>
    ),
  },
];
