import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_PROJECT_STATE,
  CLEAR_SINGLE_PROJECT,
  defaultProjectState,
  FETCH_PROJECT_LIST_FAILED,
  FETCH_PROJECT_LIST_PROGRESS,
  FETCH_PROJECT_LIST_SUCCESS,
  FETCH_SINGLE_PROJECT_FAILED,
  FETCH_SINGLE_PROJECT_PROGRESS,
  FETCH_SINGLE_PROJECT_SUCCESS,
  IQuotationActions,
} from ".";

export const projectsReducer = (
  state: IStoreState["project"] = defaultProjectState,
  action: IQuotationActions,
) => {
  switch (action.type) {
    case FETCH_PROJECT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.project_list.loading = LoadState.InProgress;
        draftState.project_list.data = [];
        draftState.project_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PROJECT_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.project_list.loading = LoadState.Loaded;
        draftState.project_list.data = list;
        draftState.project_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PROJECT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.project_list.loading = LoadState.InProgress;
        draftState.project_list.data = [];
        draftState.project_list.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_SINGLE_PROJECT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_project.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_PROJECT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_project.loading = LoadState.Loaded;
        draftState.single_project.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_PROJECT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_project.loading = LoadState.Failed;
        draftState.single_project.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_PROJECT: {
      const newState = produce(state, (draftState) => {
        draftState.single_project.loading = LoadState.NotLoaded;
        draftState.single_project.data =
          defaultProjectState["single_project"].data;
        draftState.single_project.error = null;
      });
      return newState;
    }
    case CLEAR_PROJECT_STATE: {
      return defaultProjectState;
    }
    default: {
      return state;
    }
  }
};
