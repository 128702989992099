import { LoadState } from "../../constants/enums";
import { ICustomer, ICustomerState, ILoadCustomerData } from "./customer.types";

export const defaultCustomer: ICustomer = {
  customer_uuid: "",
  customer_name: "",
  is_billing_company: 0,
  customer_website: "",
  legal_entity: "",
  created_by_uuid: "",
  organization_type: "N/A",
  registration_type: null,
  status: "ACTIVE",
};

export const defaultLoadCustomerData: ILoadCustomerData = {
  customer_name: "",
  organization_type: null,
  customer_website: null,
  legal_entity: null,
  registration_type: null,
  customer_branch_name: "",
  customer_branch_gst_in: "",
  customer_branch_address_line1: null,
  customer_branch_address_line2: null,
  customer_branch_address_city: null,
  customer_branch_address_state: null,
  customer_branch_address_pincode: null,
  customer_branch_address_country: null,
  customer_branch_mobile: null,
  customer_branch_phone_number: null,
  customer_branch_website: null,
  customer_branch_mail_id: null,
  name: "",
  mail_id: null,
  pan_no: null,
  salutation: null,
  designation: null,
  contact_type: null,
  department: null,
  extension: null,
  company_landline: null,
  fax: null,
  website: null,
  dob: null,
  previous_organisation: null,
  skype_id: null,
  executive_location_line1: null,
  executive_location_line2: null,
  executive_location_city: null,
  executive_location_state: null,
  executive_location_pincode: null,
  executive_location_country: null,
  contact_number: null,
  delivery_name: null,
  customer_delivery_address_line1: null,
  customer_delivery_address_line2: null,
  customer_delivery_address_city: null,
  customer_delivery_address_state: null,
  customer_delivery_address_pincode: null,
  customer_delivery_address_country: null,
};

export const defaultCustomerState: ICustomerState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  customer: {
    data: defaultCustomer,
    loading: LoadState.NotLoaded,
    error: null,
  },
  loadCustomerData: {
    data: defaultLoadCustomerData,
    loading: LoadState.NotLoaded,
    error: null,
  },
};

// export const initialCustomerState: ICustomer = {
//   personalInformation: {
//     data: {
//       customer_personal_information_id: 0,
//       customer_id: 0,
//       salutation: "",
//       first_name: "",
//       last_name: "",
//       goes_by: "",
//       position: "",
//       mobile: "",
//       phone: "",
//       phone_ext: "",
//       email: "",
//       status: "ACTIVE",
//       insert_ts: "",
//     },
//     loading: LoadState.NotLoaded,
//   },
//   customerProspect: {
//     data: {
//       customer_prospect_details_id: 0,
//       customer_id: 0,
//       customer_name: "",
//       csr: "",
//       customer_code: "",
//       form_of_business: "",
//       tax_zone: "ON",
//       mailing_address: "",
//       language: "E",
//       producer: "",
//       marketer: "",
//       branch: "",
//       assigned_to: null,
//       status: "ACTIVE",
//       insert_ts: "",
//       city: "",
//       province_or_state: "",
//       country: "",
//       postal_code: "",
//       unit_or_suite: "",
//     },
//     loading: LoadState.NotLoaded,
//   },
//   customerSocial: {
//     data: {
//       customer_social_id: 0,
//       customer_id: 0,
//       website: "",
//       facebook: "",
//       linkedin: "",
//       status: "ACTIVE",
//       insert_ts: "",
//     },
//     loading: LoadState.NotLoaded,
//   },
//   customerRelatedAccount: {
//     data: {
//       customer_related_account_id: "",
//       customer_id: "",
//       cross_reference_id: "",
//       status: "ACTIVE",
//       name: "",
//       insert_ts: "",
//     },
//     loading: LoadState.NotLoaded,
//   },
//   customerConsent: {
//     data: {
//       incremental_number: 0,
//       customer_id: "",
//       privacy_consent: "",
//       privacy_act_consent: "",
//       anti_spam_consent: "",
//       telematics_consent_code: "",
//       status: "ACTIVE",
//       insert_ts: "",
//     },
//     loading: LoadState.NotLoaded,
//   },
//   opportunity: {
//     data: [],
//     totalRecords: 0,
//     loading: LoadState.NotLoaded,
//   },
//   customerConsentList: {
//     data: [],
//     loading: LoadState.NotLoaded,
//   },
//   customerGroupsList: {
//     data: [],
//     loading: LoadState.NotLoaded,
//   },
//   customerFullDetails: {
//     loading: LoadState.NotLoaded,
//   },
//   customerContacts: {
//     data: [],
//     loading: LoadState.NotLoaded,
//   },

//   error: "",
// };
