import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { ALLOWED_MODULES } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";

const SecurityDashboard = Loadable(
  lazy(() =>
    import("./CreateSecurityGroup").then(({ CreateSecurityGroup }) => ({
      default: CreateSecurityGroup,
    })),
  ),
);

const SecurityApprovalsList = Loadable(
  lazy(() =>
    import("./approval/ApprovalList").then(({ ApprovalList }) => ({
      default: ApprovalList,
    })),
  ),
);

const ManageSecuirtyApproval = Loadable(
  lazy(() =>
    import("./approval/ManageApproval").then(({ ManageApproval }) => ({
      default: ManageApproval,
    })),
  ),
);

const ListSecurityGroups = Loadable(
  lazy(() =>
    import("./ListSecurityGroups").then(({ ListSecurityGroups }) => ({
      default: ListSecurityGroups,
    })),
  ),
);

const RoleGroupTable = Loadable(
  lazy(() =>
    import("./roleGroup/RoleGroupTable").then(({ RoleGroupTable }) => ({
      default: RoleGroupTable,
    })),
  ),
);

export const securityRoutes = [
  {
    path: "/list-security-groups",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.SECURITY]}>
        <ListSecurityGroups />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create-security-group",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.SECURITY]}>
        <SecurityDashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create-security-group/:roleId",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.SECURITY]}>
        <SecurityDashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/secuirty-role-duplicate/:roleId",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.SECURITY]}>
        <SecurityDashboard isDuplicate />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/security/approvals-list",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.SECURITY]}>
        <SecurityApprovalsList />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/security/manage-approval",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.SECURITY]}>
        <ManageSecuirtyApproval />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/security/manage-approval/:approvalId",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.SECURITY]}>
        <ManageSecuirtyApproval />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/security/role-groups",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.SECURITY]}>
        <RoleGroupTable />
      </AuthorizedRoute>
    ),
  },
];
