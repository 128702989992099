import { LoadState } from "../../constants/enums";
import {
  IRendering,
  IRenderingPartner,
  IRenderingState,
} from "./rendering.types";

export const defaultRenderingPartner: IRenderingPartner = {
  rendering_partner_id: null,
  rendering_partner_uuid: null,
  company_name: null,
  contact_person_name: null,
  registered_phone: null,
  company_address: null,
  gst_in: null,
  website: null,
  created_by_uuid: null,
  status: "",
};

export const defaultRendering: IRendering = {
  rendering_id: null,
  rendering_uuid: null,
  rendering_partner_uuid: null,
  rendering_partner_name: null,
  project_name: null,
  project_type: null,
  details: null,
  bill_raised_amount: null,
  delivery_date: null,
  contact_person_name: null,
  contact_email: null,
  contact_number: null,
  assigned_to_uuid: null,
  assigned_to_name: null,
  attachments: {},
  created_by_uuid: null,
  status: "Requested",
};

export const defaultRenderingState: IRenderingState = {
  // rendering_partner_list: {
  //     data: [],
  //     totalRecords: 0,
  //     loading: LoadState.NotLoaded
  // },
  // rendering_partner: {
  //     data: defaultRenderingPartner,
  //     loading: LoadState.NotLoaded,
  //     error: null
  // },
  rendering_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  rendering: {
    data: defaultRendering,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
