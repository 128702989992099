export const SECURITY_ROUTE_UPSERT_ROLES = "/security/upsert-roles";
export const SECURITY_ROUTE_GET_ROLES = "/security/get-roles";

export const SECURITY_ROUTE_UPSERT_ROLE_GROUPS = "";
export const SECURITY_ROUTE_GET_ROLE_GROUPS = "";

export const SECURITY_ROUTE_UPSERT_RMCAP =
  "/security/upsert-role-module-content-access-permission";
export const SECURITY_ROUTE_GET_RMCAP =
  "/security/get-role-module-content-access-permission";

export const SECURITY_ROUTE_GET_MODULES = "";
