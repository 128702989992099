import { LoadState } from "../../constants/enums";
import { IInvoiceItem } from "../invoice";

export interface IProjectState {
  project_list: {
    data: IProject[];
    totalRecords: number;
    loading: LoadState;
  };
  single_project: {
    data: IProject;
    loading: LoadState;
    error: string | null;
  };
}

export interface IProject {
  project_uuid: string | null;
  project_name: string;
  project_code: string | null;
  project_location: string | null;
  nit_no: string | null;
  bid_no: string | null;
  bid_date: string | null; // YYYY-MM-DD
  contract_no: string | null;
  agency_name: string | null;
  start_date: string | null; // YYYY-MM-DD
  completion_date: string | null; // YYYY-MM-DD
  estimated_cost: number;
  tender_amount: number;
  project_managers: IProjectManager[];
  remark: string | null;
  items: IProjectItem[];
  status: "ACTIVE" | "INACTIVE";

  // Ready Only
  combined_project_code?: string;
  insert_ts?: string;
  create_ts?: string;
}

export interface IProjectManager {
  user_uuid: string;
  user_name: string;
}

export interface IProjectItem {
  serial_no: number;
  item_description: string;
  image: string;
  tender_qty: number;
  revised_qty: number;
  quoted_rate: number;
  discount: number;
  final_rate: number;
  booking_details_qty: number;
  booking_details_rate: number;
  booking_details_vendor: string;
  goods_in_qty: number;
  goods_in_rate: number;
  goods_in_vendor: string;
  goods_out_warehouse_site: string;
  goods_out_qty: number;
  goods_out_rate: number;
  goods_out_amount: number;
  remaining_qty: number;
}

export const defaultProjectItem: IProjectItem = {
  serial_no: 0,
  item_description: "",
  image: "",
  tender_qty: 0,
  revised_qty: 0,
  quoted_rate: 0,
  discount: 0,
  final_rate: 0,
  booking_details_qty: 0,
  booking_details_rate: 0,
  booking_details_vendor: "",
  goods_in_qty: 0,
  goods_in_rate: 0,
  goods_in_vendor: "",
  goods_out_warehouse_site: "",
  goods_out_qty: 0,
  goods_out_rate: 0,
  goods_out_amount: 0,
  remaining_qty: 0,
};
