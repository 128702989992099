import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { APP_PROJECT_ROUTE } from "./Project.constants";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ALLOWED_MODULES } from "../../constants/enums";

//************************ Vendor Imports ***************************/
const ProjectTable = Loadable(
  lazy(() =>
    import("./ProjectTable").then(({ ProjectTable }) => ({
      default: ProjectTable,
    })),
  ),
);

const ManageSingleProject = Loadable(
  lazy(() =>
    import("./ManageSingleProject").then(({ ManageSingleProject }) => ({
      default: ManageSingleProject,
    })),
  ),
);

//************************ Product Routes ***************************/
export const appProjectRoutes = [
  {
    path: APP_PROJECT_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PROJECT]}>
        <ProjectTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_PROJECT_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PROJECT]}>
        <ManageSingleProject />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_PROJECT_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[ALLOWED_MODULES.PROJECT]}>
        <ManageSingleProject />
      </AuthorizedRoute>
    ),
  },
];
