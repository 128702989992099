import React from "react";
import WebFont from "webfontloader";

const FontsContext = React.createContext({});

export const useAuth = () => React.useContext(FontsContext);

export const FontsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["Inter:400,600,700"],
      },
    });
  }, []);

  return <FontsContext.Provider value={{}}>{children}</FontsContext.Provider>;
};
