import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { InventoryActions } from ".";
import {
  CLEAR_INVENTORY,
  CLEAR_INVENTORY_STATE,
  FETCH_INVENTORY_FAILED,
  FETCH_INVENTORY_LIST_FAILED,
  FETCH_INVENTORY_LIST_PROGRESS,
  FETCH_INVENTORY_LIST_SUCCESS,
  FETCH_INVENTORY_PROGRESS,
  FETCH_INVENTORY_SUCCESS,
} from "./inventoryActions";
import { defaultInventory, defaultInventoryState } from "./defaultState";

export const inventoryReducer = (
  state: IStoreState["inventory"] = defaultInventoryState,
  action: InventoryActions,
) => {
  switch (action.type) {
    case FETCH_INVENTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.inventoryList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_INVENTORY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.inventoryList.loading = LoadState.Loaded;
        draftState.inventoryList.data = data;
        draftState.inventoryList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_INVENTORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.inventoryList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_INVENTORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.inventory.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_INVENTORY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.inventory.loading = LoadState.Loaded;
        draftState.inventory.data = data;
      });
      return newState;
    }
    case FETCH_INVENTORY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.inventory.loading = LoadState.Failed;
        draftState.inventory.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_INVENTORY: {
      const newState = produce(state, (draftState) => {
        draftState.inventory.loading = LoadState.NotLoaded;
        draftState.inventory.data = defaultInventory;
        draftState.inventory.error = null;
      });
      return newState;
    }

    case CLEAR_INVENTORY_STATE: {
      return defaultInventoryState;
    }
    default: {
      return state;
    }
  }
};
